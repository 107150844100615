import BaseController from '@/service/BaseService'
import ToolRequest from '@/request/Business/ToolRequest'
import { errorTip } from '@/utils/Tip'
import McProject from '@/components/McProject.vue'

const base = new BaseController()

export default {
  components: { McProject },
  data () {
    return {
      list: [],
      teacher: [],
      teacher_id: '',
      message: '',
      fullscreenLoading: false
    }
  },
  // 初始化函数
  created: async function () {
    await base.init(this, new ToolRequest())
    await this.getList()
  },
  methods: {
    async getList () {
      const result = await base.request.listPost()
      if (result.code !== 200) return
      this.teacher = result.data.teacher
    },
    // 官网导入
    webSiteClick: async function () {
      const teacherId = this.teacher_id
      const webSite = this.list
      if (teacherId === null || teacherId === '') errorTip(this, '请选择老师')
      if (webSite.length === 0) errorTip(this, '未导入网站')
      this.$refs.project.init(webSite.length)
      const messages = []
      for (let i = 0; i < webSite.length; i++) {
        await new Promise((resolve) => {
          setTimeout(async () => {
            try {
              webSite[i].teacherId = teacherId
              const result = await base.request.addPost(webSite[i])
              messages.push(result.message)
              this.$refs.project.setInc()
            } catch (error) {
              messages.push('请求失败')
            }
            resolve()
          }, 1000) // 设置延迟时间
        })
      }
      this.message = messages.join('\r\n')
    },
    // 宝塔建站
    baoTaClick: async function () {
      const webSite = this.list
      if (webSite.length === 0) errorTip(this, '未导入网站')
      this.$refs.project.init(webSite.length)

      const messages = []
      for (let i = 0; i < webSite.length; i++) {
        await new Promise((resolve) => {
          setTimeout(async () => {
            try {
              const result = await base.request.baoTaPost(webSite[i])
              messages.push(result.message)
              this.$refs.project.setInc()
            } catch (error) {
              messages.push('请求失败')
            }
            resolve()
          }, 1000) // 设置延迟时间
        })
      }
      this.message = messages.join('\r\n')
    },

    // 邮箱
    mailClick: async function () {
      const webSite = this.list
      if (webSite.length === 0) errorTip(this, '未导入网站')
      // 任务初始化
      this.$refs.project.init(webSite.length)
      const messages = []
      for (let i = 0; i < webSite.length; i++) {
        await new Promise((resolve) => {
          setTimeout(async () => {
            try {
              const result = await base.request.mailPost(webSite[i])
              messages.push(result.message)
              this.$refs.project.setInc()
            } catch (error) {
              messages.push('请求失败')
            }
            resolve()
          }, 1000) // 设置延迟时间
        })
      }
      this.message = messages.join('\r\n')
    },
    // 导入事件
    importClick: async function ({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      const result = await base.request.importPost(formData)
      this.list = result.data
    }
  }
}
